var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition-group', {
    staticClass: "c-toasts",
    attrs: {
      "name": "toasts",
      "tag": "div"
    }
  }, _vm._l(_vm.toasts, function (toast) {
    return _c('ToastItem', {
      key: toast.id,
      staticClass: "toasts-item",
      attrs: {
        "toast": toast
      },
      on: {
        "close": _vm.removeToast
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }